import logo from './fb.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Frank Boelen
        </h1>
        <h2>
            <span className="Bold">
                <a href="tel://+31616592953">+31 6 16 59 29 53</a>
                <br />
                <a href="https://www.linkedin.com/in/frankboelen/">linkedin.com/in/frankboelen</a>
            </span>
        </h2>
        <h2>
            I enjoy helping with complex IT&nbsp;projects&nbsp;as&nbsp;a <br />
            <span className="Bold">
                product manager
            </span>, <span className="Bold">
                
                project manager
            </span> or <span className="Bold">
                program manager
            </span>
        </h2>
        <h2>
            Region Rotterdam, Randstad, NL / Hybrid
        </h2>

      </header>
    </div>
  );
}

export default App;
